import { defineComponentClasses } from '@visiontree/vue-ui'

interface Structure {
  container: never
  input: never
  label: never
  end: never
}

type Options = Record<never, never>

export const getSearchInputClasses = defineComponentClasses<Options, Structure>(
  {
    classes: {
      container: 'relative flex flex-row items-center',
      input:
        'block w-full rounded-full border border-neutral-300 bg-neutral-50 py-3 pl-6 pr-12 text-sm text-neutral-900 focus:border-primary-500 focus:ring-primary-500 dark:border-neutral-600 dark:bg-neutral-700 dark:text-neutral-100 dark:placeholder-neutral-400 dark:focus:border-primary-500 dark:focus:ring-primary-500',
      label: 'sr-only',
      end: '-ml-10',
    },
  }
)
